import React from "react";
import { createRoot } from "react-dom/client";
import { clone, find, flatten, isEmpty, map, startsWith } from "lodash";

import {
  ButtonFrameToDownload,
  MobileFrameToDownload,
  TicketFrameToDownload,
  MuseumFrameToDownload,
  WhiteFrameToDownload,
  BlackFrameToDownload,
  CafeFrameToDownload,
  ShoppingFrameToDownload,
  BoardFrameToDownload,
  EnvelopeFrameToDownload,
  ReceiptFrameToDownload,
  EyelashesFrameToDownload,
  HairsalonFrameToDownload,
  BeautyFrameToDownload,
  CareFrameToDownload,
  WaiterFrameToDownload,
  BucketFrameToDownload,
  TakeawayFrameToDownload,
  CupFrameToDownload,
  BrochureFrameToDownload,
  AsianFrameToDownload,
  SodaFrameToDownload,
  MenuFrameToDownload,
  RestaurantFrameToDownload,
  WineFrameToDownload,
  HeadphonesFrameToDownload,
  MicFrameToDownload,
  ConcertFrameToDownload,
  VideogameFrameToDownload,
  MovieFrameToDownload,
  MusicFrameToDownload,
  Mp3FrameToDownload,
  FilmFrameToDownload,
  HandFrameToDownload,
  LikeFrameToDownload,
  ChatFrameToDownload,
  StarsFrameToDownload,
  ReviewFrameToDownload,
  Ticket2FrameToDownload,
  WeddingFrameToDownload,
  ChristmasFrameToDownload,
  PartyFrameToDownload,
  TicketsFrameToDownload,
  CelebrationFrameToDownload,
  PartyhatFrameToDownload,
  BalloonsFrameToDownload,
  GarlandFrameToDownload,
  BouquetFrameToDownload,
  GiftboxFrameToDownload,
  CardsFrameToDownload,
  ShoppingbagFrameToDownload,
  CartFrameToDownload,
  NoneFrameToDownload,
} from "@/components/QRFrame/framesToPrint";

import { FrameType, QRFRameToPrintProps, QRFrameStyle } from "@/components/QRFrame/types";
import { QrCodeResponse } from "@/interface/api/getAllQrCodes/types";

import { BusinessHoursDays } from "@/components/BusinessHours/types";
import { QRDownloadFormat, QrCodeProps } from "@/types/qr";

import { socials } from "@/internals/constants/socials";
import { QREditorContextProps, QRProps } from "@/contexts/QREditorContext/types";
import { INITIAL_QR_DATA, QREditorContext } from "@/contexts/QREditorContext";

import { QREditorAPItoDataConversor } from "@/services/ApiConversorService/QREditor";

import { isClient } from "./browser";

import { onBindClick } from "./formHelper";
import {
  themePickerCustomColors,
  themePickerInitialColors,
} from "@/components/ThemePicker/ThemePicker";

import {
  asianFrame,
  balloonsFrame,
  beautyFrame,
  blackFrame,
  boardFrame,
  bouquetFrame,
  brochureFrame,
  bucketFrame,
  buttonFrame,
  cafeFrame,
  cardsFrame,
  careFrame,
  cartFrame,
  celebrationFrame,
  chatFrame,
  christmasFrame,
  concertFrame,
  cupFrame,
  envelopeFrame,
  eyelashesFrame,
  filmFrame,
  garlandFrame,
  giftboxFrame,
  hairsalonFrame,
  handFrame,
  headphonesFrame,
  likeFrame,
  menuFrame,
  micFrame,
  mobileFrame,
  movieFrame,
  mp3Frame,
  museumFrame,
  musicFrame,
  partyFrame,
  partyhatFrame,
  receiptFrame,
  restaurantFrame,
  reviewFrame,
  shoppingbagFrame,
  shoppingFrame,
  sodaFrame,
  starsFrame,
  takeawayFrame,
  ticket2Frame,
  ticketFrame,
  ticketsFrame,
  videogameFrame,
  waiterFrame,
  weddingFrame,
  whiteFrame,
  wineFrame,
} from "@/components/QRFrame/frames";
import { getqrCodeSize, qrHeight, qrWidth } from "./framesHelper";
import { isStaticQr } from "./qrContentType";

export const onBindSocialNetworks = (
  dataWithDefaultValues: any,
  componentKey: string,
  localStorageKey?: string,
  ommitedValues?: string[],
  links?: boolean,
  newData?: QRProps<"links" | "social">
) => {
  const qrEditorContext = React.useContext<QREditorContextProps<any>>(QREditorContext);

  onBindClick(dataWithDefaultValues, componentKey || "qrWebsiteSocials", (data: any[]) => {
    qrEditorContext?.setQrData(
      {
        ...qrEditorContext?.qrData,
        data: {
          ...qrEditorContext?.qrData.data,
          [localStorageKey]: [...data],
          ...(links && { links: newData?.data?.links }),
        },
      },
      ommitedValues || map(socials, (social) => `data.${social.id}`)
    );
  });
};

export const onBindThemePicker = (
  dataWithDefaultValues: any,
  componentKey: string,
  ommitedValues?: string[]
) => {
  const qrEditorContext = React.useContext<QREditorContextProps<any>>(QREditorContext);

  onBindClick(dataWithDefaultValues, componentKey || "qrwebsiteThemePicker", (colorKey: string) => {
    let themeColors = themePickerInitialColors;
    !isEmpty(themePickerCustomColors)
      ? (themeColors = themeColors.concat(themePickerCustomColors))
      : themeColors;

    const theme = find(themeColors, { key: colorKey });
    qrEditorContext?.setQrData(
      {
        ...qrEditorContext?.qrData,
        data: {
          ...qrEditorContext?.qrData.data,
          themeColor: {
            buttonColor: theme?.primary,
            backgroundColor: theme?.secondary,
          },
        },
      },
      ommitedValues
    );
  });
};

export const onBindImage = (
  dataWithDefaultValues: any,
  componentKey: string,
  ommitedValues?: string[]
) => {
  const qrEditorContext = React.useContext<QREditorContextProps<any>>(QREditorContext);
  const [imageBase64, setImageBase64] = React.useState("");

  onBindClick(
    dataWithDefaultValues,
    componentKey || "qrWebsiteInformationLogo",
    (imgBase64: string[]) => setImageBase64(imgBase64[0])
  );

  React.useEffect(() => {
    qrEditorContext?.setQrData(
      {
        ...qrEditorContext?.qrData,
        data: { ...qrEditorContext?.qrData.data, logoUrl: imageBase64 },
      },
      ommitedValues || ["data.logoUrl", "data.file"]
    );
  }, [imageBase64]);
};

export const getQRCodeUrl = (qrData: QRProps<any>, isPreview?: boolean) => {
  const isWifi = qrData.type === "wifi";
  const isText = qrData.type === "text";
  const qrCode = qrData.qrCodeReference;
  const origin = isClient() ? window.location.origin : undefined;

  if (isWifi)
    return `WIFI:T:${qrData?.data?.qrNetworkSecurityType};S:${
      qrData?.data?.qrNetworkName
    };P:${qrData?.data?.qrNetworkPassword};H:${qrData?.data?.qrNetworkHidden || false}`;

  if (isText) return qrData?.data?.message;

  return origin && qrCode ? `${origin}${isPreview ? "/p/" : "/qr/"}${qrCode}` : origin;
};

export const isValidQrReference = (reference: string) => {
  const referenceRegex = new RegExp("^[0-9a-fA-F]{8}$");
  return referenceRegex.test(reference);
};

export const downloadQrAi = async (
  data: any,
  url: string,
  extension: QRDownloadFormat,
  nameOfFile: string
) => {
  const fileData = {
    type: extension === "png" ? "image/png" : extension === "jpeg" ? "image/jpeg" : "image/webp",
    name:
      extension === "png"
        ? `${nameOfFile}.png`
        : extension === "jpeg"
          ? `${nameOfFile}.jpg`
          : `${nameOfFile}.webp`,
  };

  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileData.name;

    link.click();

    window.URL.revokeObjectURL(link.href);
  } catch (err) {
    console.error("Error downloading image:", err);
  }
};

export const areWeInTheEditor = (): boolean =>
  isClient() && window.location.pathname.includes("/qr-editor");

export const getFramedQrToDownload = (
  frameStyle: FrameType,
  qrCodeSvg: string,
  data: QRFrameStyle
): React.SVGProps<SVGSVGElement> => {
  // Temporary fix -> QR-4379 remove vaidation frameStyle === "none"
  if (!frameStyle) return;

  const sharedObject: QRFRameToPrintProps = {
    frameBackgroundColor: data.frameBackgroundColor,
    frameColor: data.frameColor,
    textColor: data.frameTextColor,
    text: data.frameText,
    qrSvg: qrCodeSvg,
    width: qrWidth(frameStyle),
    height: qrHeight(frameStyle),
  };

  switch (frameStyle) {
    // Temporary fix -> QR-4379 add case "none"
    case "none":
      return NoneFrameToDownload(sharedObject);
    case "button":
      return ButtonFrameToDownload(sharedObject);
    case "mobile":
      return MobileFrameToDownload(sharedObject);
    case "ticket":
      return TicketFrameToDownload(sharedObject);
    case "museum":
      return MuseumFrameToDownload(sharedObject);
    case "white":
      return WhiteFrameToDownload(sharedObject);
    case "black":
      return BlackFrameToDownload(sharedObject);
    case "cafe":
      return CafeFrameToDownload(sharedObject);
    case "shopping":
      return ShoppingFrameToDownload(sharedObject);
    case "board":
      return BoardFrameToDownload(sharedObject);
    case "envelope":
      return EnvelopeFrameToDownload(sharedObject);
    case "receipt":
      return ReceiptFrameToDownload(sharedObject);
    case "eyelashes":
      return EyelashesFrameToDownload(sharedObject);
    case "hairsalon":
      return HairsalonFrameToDownload(sharedObject);
    case "beauty":
      return BeautyFrameToDownload(sharedObject);
    case "care":
      return CareFrameToDownload(sharedObject);
    case "waiter":
      return WaiterFrameToDownload(sharedObject);
    case "bucket":
      return BucketFrameToDownload(sharedObject);
    case "takeaway":
      return TakeawayFrameToDownload(sharedObject);
    case "cup":
      return CupFrameToDownload(sharedObject);
    case "brochure":
      return BrochureFrameToDownload(sharedObject);
    case "asian":
      return AsianFrameToDownload(sharedObject);
    case "soda":
      return SodaFrameToDownload(sharedObject);
    case "menu":
      return MenuFrameToDownload(sharedObject);
    case "restaurant":
      return RestaurantFrameToDownload(sharedObject);
    case "wine":
      return WineFrameToDownload(sharedObject);
    case "headphones":
      return HeadphonesFrameToDownload(sharedObject);
    case "mic":
      return MicFrameToDownload(sharedObject);
    case "concert":
      return ConcertFrameToDownload(sharedObject);
    case "videogame":
      return VideogameFrameToDownload(sharedObject);
    case "movie":
      return MovieFrameToDownload(sharedObject);
    case "music":
      return MusicFrameToDownload(sharedObject);
    case "mp3":
      return Mp3FrameToDownload(sharedObject);
    case "film":
      return FilmFrameToDownload(sharedObject);
    case "hand":
      return HandFrameToDownload(sharedObject);
    case "like":
      return LikeFrameToDownload(sharedObject);
    case "chat":
      return ChatFrameToDownload(sharedObject);
    case "stars":
      return StarsFrameToDownload(sharedObject);
    case "review":
      return ReviewFrameToDownload(sharedObject);
    case "ticket2":
      return Ticket2FrameToDownload(sharedObject);
    case "wedding":
      return WeddingFrameToDownload(sharedObject);
    case "christmas":
      return ChristmasFrameToDownload(sharedObject);
    case "party":
      return PartyFrameToDownload(sharedObject);
    case "tickets":
      return TicketsFrameToDownload(sharedObject);
    case "celebration":
      return CelebrationFrameToDownload(sharedObject);
    case "partyhat":
      return PartyhatFrameToDownload(sharedObject);
    case "balloons":
      return BalloonsFrameToDownload(sharedObject);
    case "garland":
      return GarlandFrameToDownload(sharedObject);
    case "bouquet":
      return BouquetFrameToDownload(sharedObject);
    case "giftbox":
      return GiftboxFrameToDownload(sharedObject);
    case "cards":
      return CardsFrameToDownload(sharedObject);
    case "shoppingbag":
      return ShoppingbagFrameToDownload(sharedObject);
    case "cart":
      return CartFrameToDownload(sharedObject);
  }
};

export const getFramedQr = (
  frameStyle: FrameType | undefined,
  reference: React.MutableRefObject<any> | React.ReactElement | any,
  data: QrCodeResponse | any,
  urlQr?: string
): React.SVGProps<SVGSVGElement> | React.MutableRefObject<any> | React.ReactElement | any => {
  if (!frameStyle || frameStyle === "none") return reference;

  const sharedObject = {
    children:
      reference.type === "img" ? reference : <div ref={reference as React.MutableRefObject<any>} />,
    frameBackgroundColor: data.style.frameBackgroundColor,
    frameColor: data.style.frameColor,
    textColor: data.style.frameTextColor,
    text: data.style.frameText,
    urlQr,
  };

  switch (frameStyle) {
    case "ticket":
      return ticketFrame(sharedObject);
    case "black":
      return blackFrame(sharedObject);
    case "button":
      return buttonFrame(sharedObject);
    case "mobile":
      return mobileFrame(sharedObject);
    case "museum":
      return museumFrame(sharedObject);
    case "white":
      return whiteFrame(sharedObject);
    case "cafe":
      return cafeFrame(sharedObject);
    case "shopping":
      return shoppingFrame(sharedObject);
    case "board":
      return boardFrame(sharedObject);
    case "envelope":
      return envelopeFrame(sharedObject);
    case "receipt":
      return receiptFrame(sharedObject);
    case "eyelashes":
      return eyelashesFrame(sharedObject);
    case "hairsalon":
      return hairsalonFrame(sharedObject);
    case "beauty":
      return beautyFrame(sharedObject);
    case "care":
      return careFrame(sharedObject);
    case "waiter":
      return waiterFrame(sharedObject);
    case "bucket":
      return bucketFrame(sharedObject);
    case "takeaway":
      return takeawayFrame(sharedObject);
    case "cup":
      return cupFrame(sharedObject);
    case "brochure":
      return brochureFrame(sharedObject);
    case "asian":
      return asianFrame(sharedObject);
    case "soda":
      return sodaFrame(sharedObject);
    case "menu":
      return menuFrame(sharedObject);
    case "restaurant":
      return restaurantFrame(sharedObject);
    case "wine":
      return wineFrame(sharedObject);
    case "headphones":
      return headphonesFrame(sharedObject);
    case "mic":
      return micFrame(sharedObject);
    case "concert":
      return concertFrame(sharedObject);
    case "videogame":
      return videogameFrame(sharedObject);
    case "movie":
      return movieFrame(sharedObject);
    case "music":
      return musicFrame(sharedObject);
    case "mp3":
      return mp3Frame(sharedObject);
    case "film":
      return filmFrame(sharedObject);
    case "hand":
      return handFrame(sharedObject);
    case "like":
      return likeFrame(sharedObject);
    case "chat":
      return chatFrame(sharedObject);
    case "stars":
      return starsFrame(sharedObject);
    case "review":
      return reviewFrame(sharedObject);
    case "ticket2":
      return ticket2Frame(sharedObject);
    case "wedding":
      return weddingFrame(sharedObject);
    case "christmas":
      return christmasFrame(sharedObject);
    case "party":
      return partyFrame(sharedObject);
    case "tickets":
      return ticketsFrame(sharedObject);
    case "celebration":
      return celebrationFrame(sharedObject);
    case "partyhat":
      return partyhatFrame(sharedObject);
    case "balloons":
      return balloonsFrame(sharedObject);
    case "garland":
      return garlandFrame(sharedObject);
    case "bouquet":
      return bouquetFrame(sharedObject);
    case "giftbox":
      return giftboxFrame(sharedObject);
    case "cards":
      return cardsFrame(sharedObject);
    case "shoppingbag":
      return shoppingbagFrame(sharedObject);
    case "cart":
      return cartFrame(sharedObject);
  }
};

export const getPreviewFrameFontSize = (textLength?: number) => {
  let fontSize = "9px";

  if (textLength >= 12) {
    fontSize = "7px";
  }
  if (textLength >= 16) {
    fontSize = "5px";
  }
  if (textLength >= 23) {
    fontSize = "4px";
  }

  return fontSize;
};

export const getPreviewFramePrintFontSize = (textLength: number) => {
  let fontSize = "100px";

  if (textLength >= 12) {
    fontSize = "90px";
  }
  if (textLength >= 16) {
    fontSize = "60px";
  }
  if (textLength >= 23) {
    fontSize = "40px";
  }

  return fontSize;
};

const renderToString = async (element: React.ReactElement) => {
  const container = document.createElement("div");
  const root = createRoot(container);
  root.render(element);

  await new Promise((resolve) => setTimeout(resolve, 100));

  const html = container.innerHTML;
  root.unmount();

  return html;
};

export const downloadQrFramed = (
  qrCode: any,
  data: QRFrameStyle,
  extension: QRDownloadFormat,
  nameOfFile: string
) => {
  if (!qrCode) return;

  qrCode?.update(getqrCodeSize(data?.frameStyle));

  qrCode?.getRawData("svg").then((blobData) => {
    const url = URL.createObjectURL(blobData);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();

        reader.onloadend = async () => {
          let svgText = reader.result as string;

          const startIndex = svgText.indexOf("<svg");
          const endIndex = svgText.lastIndexOf(">") + 1;
          const svgContent = svgText.substring(startIndex, endIndex);

          let positionVariables = "x='22' y='19'";
          switch (data?.frameStyle) {
            // Temporary fix -> QR-4379 add case "none"
            case "none":
              positionVariables = "x='0' y='-30'";
              break;
            case "ticket":
              positionVariables = "x='22' y='25' ";
              break;
            case "museum":
            case "black":
            case "white":
              positionVariables = "x='23' y='20' ";
              break;
            case "eyelashes":
            case "hairsalon":
              positionVariables = "x='32' y='65' ";
              break;
            case "beauty":
              positionVariables = "x='56' y='74' ";
              break;
            case "care":
              positionVariables = "x='56' y='39' ";
              break;
            case "waiter":
              positionVariables = "x='32' y='33' ";
              break;
            case "bucket":
              positionVariables = "x='33' y='60' ";
              break;
            case "takeaway":
            case "soda":
              positionVariables = "x='40' y='80' ";
              break;
            case "cup":
              positionVariables = "x='56' y='84' ";
              break;
            case "brochure":
              positionVariables = "x='73' y='88' ";
              break;
            case "asian":
              positionVariables = "x='57' y='100' ";
              break;
            case "menu":
              positionVariables = "x='30' y='98' ";
              break;
            case "restaurant":
              positionVariables = "x='62' y='84' ";
              break;
            case "wine":
              positionVariables = "x='81' y='108' ";
              break;
            case "headphones":
              positionVariables = "x='45' y='85' ";
              break;
            case "mic":
              positionVariables = "x='45' y='102' ";
              break;
            case "concert":
              positionVariables = "x='38' y='77' ";
              break;
            case "videogame":
            case "music":
            case "shoppingbag":
              positionVariables = "x='45' y='78' ";
              break;
            case "movie":
              positionVariables = "x='45' y='89' ";
              break;
            case "mp3":
            case "ticket2":
              positionVariables = "x='45' y='65' ";
              break;
            case "film":
              positionVariables = "x='42' y='96' ";
              break;
            case "like":
            case "chat":
            case "stars":
            case "review":
              positionVariables = "x='45' y='92' ";
              break;
            case "wedding":
              positionVariables = "x='37' y='76' ";
              break;
            case "christmas":
            case "celebration":
            case "bouquet":
              positionVariables = "x='45' y='99' ";
              break;
            case "tickets":
              positionVariables = "x='25' y='34' ";
              break;
            case "partyhat":
              positionVariables = "x='38' y='65' ";
              break;

            case "balloons":
            case "bouquet":
            case "giftbox":
              positionVariables = "x='40' y='83' ";
              break;
            case "cards":
              positionVariables = "x='61.800' y='87' ";
              break;
            case "cart":
              positionVariables = "x='51.800' y='44' ";
              break;
            case "hand":
              positionVariables = "x='40' y='75' ";
              break;
            case "party":
              positionVariables = "x='40' y='82' ";
              break;
            case "garland":
              positionVariables = "x='40' y='84.950' ";
              break;
          }

          const svgContentUpdated =
            svgContent.substring(0, 5) + positionVariables + svgContent.substring(5);

          const svgResult = getFramedQrToDownload(data?.frameStyle, svgContentUpdated, data) as any;
          const jsxElement = React.createElement(svgResult.type, svgResult.props);

          let svgString = await renderToString(jsxElement);

          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
          const imgElement = svgDoc.querySelector("image");

          if (imgElement) {
            let href = imgElement.getAttribute("href");
            if (href) {
              const response = await fetch(href);
              const blob = await response.blob();
              const imgReader = new FileReader();

              const base64Data = await new Promise((resolve) => {
                imgReader.onloadend = () => resolve(imgReader.result);
                imgReader.readAsDataURL(blob);
              });

              imgElement.setAttribute("href", base64Data as string);
            }
          }

          const serializer = new XMLSerializer();
          svgString = serializer.serializeToString(svgDoc.documentElement);

          if (extension === "png" || extension === "jpeg" || extension === "webp") {
            const fileData = {
              type:
                extension === "png"
                  ? "image/png"
                  : extension === "jpeg"
                    ? "image/jpeg"
                    : "image/webp",
              name:
                extension === "png"
                  ? `${nameOfFile}.png`
                  : extension === "jpeg"
                    ? `${nameOfFile}.jpg`
                    : `${nameOfFile}.webp`,
            };

            const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
            const imgUrl = URL.createObjectURL(svgBlob);

            const img = new Image();
            img.src = imgUrl;

            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;

              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);

              const link = document.createElement("a");
              link.href = canvas.toDataURL(fileData.type);
              link.download = fileData.name;
              link.click();

              URL.revokeObjectURL(imgUrl);
            };
          } else if (extension === "svg") {
            const blobb = new Blob([svgString], { type: "image/svg+xml" });
            const blobUrl = URL.createObjectURL(blobb);

            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = `${nameOfFile}.svg`;
            link.click();

            URL.revokeObjectURL(blobUrl);
          }
        };

        reader.readAsText(blob);
      })
      .catch((error) => {
        console.error("Error fetching SVG:", error);
      });
  });
};

export const buildQRWifiUrl = (qrData: QrCodeProps | QrCodeResponse) =>
  `WIFI:T:${qrData?.content?.["network-security-type"]};S:${
    qrData?.content?.["network-name"]
  };P:${qrData?.content?.["network-password"]};H:${
    qrData?.content?.["network-hidden-password"] || false
  }`;

export const buildQRTextUrl = (qrData: QrCodeProps | QrCodeResponse) => {
  return `${qrData?.content?.["message"]}`;
};

export const buildStaticUrl = (qrData: QrCodeProps | QrCodeResponse) => {
  switch (qrData?.type) {
    case "Wifi":
      return buildQRWifiUrl(qrData);
    case "Text":
      return buildQRTextUrl(qrData);
    default:
      return "";
  }
};

export const buildPreviewUrl = (qrData: QrCodeProps | QrCodeResponse, location: string) => {
  return isStaticQr(qrData?.type) ? buildStaticUrl(qrData) : `${location}/qr/${qrData?.reference}`;
};

export const getEmptyDays = (): BusinessHoursDays => {
  return {
    monday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    tuesday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    wednesday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    thursday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    friday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    saturday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    sunday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
  };
};

export const isFileAblob = (file) => {
  const FILTER_PREFIX = "blob:";

  return startsWith(file, FILTER_PREFIX);
};

const omittedLinks = (data: any) => {
  return data?.links?.length
    ? flatten(
        map(Array.from(Array(10).keys()), (el) => {
          return data.links &&
            isFileAblob(data?.links[el]?.linkImageUrl && data?.links[el]?.linkImageUrl[0])
            ? [`data.text-link-${el}`, `data.url-link-${el}`, `data.links[${el}].linkImageUrl[0]`]
            : [`data.text-link-${el}`, `data.url-link-${el}`];
        })
      )
    : [];
};

export const omittedValues = (data: any) => {
  return isFileAblob(data?.logoUrl)
    ? [
        ...map(socials, (social) => `data.${social.id}`),
        "data.logoUrl",
        "data.file",
        "qrStyle.logoUrl",
        ...omittedLinks(data),
      ]
    : [...map(socials, (social) => `data.${social.id}`), "data.file", ...omittedLinks(data)];
};

export const generateQrDataClone = (
  qrEditorContext: QREditorContextProps<any>,
  qrType: string,
  name?: string
): QRProps<any> => {
  const newQRData = clone(qrEditorContext?.qrData);

  if (newQRData) {
    newQRData.type = qrType as any;
    newQRData.data = {
      ...INITIAL_QR_DATA.data,
      typeName: QREditorAPItoDataConversor.QRTypeTransform(qrType) || name,
    };
    newQRData.qrStyle = { ...INITIAL_QR_DATA.qrStyle, logoUrl: "" };
    newQRData.isValidForm = false;
    newQRData.qrCodeId = undefined;
    newQRData.qrCodeReference = undefined;
  }

  return newQRData;
};

export const setNewQRData = (qrEditorContext, type, name?) => {
  const newQRData = generateQrDataClone(qrEditorContext, type, name);

  qrEditorContext?.setQrData(newQRData);
};

export const strValid = (str: string, punctuation?: string): string =>
  str ? `${str}${punctuation ?? ""} ` : "";

export const getFullAddress = (address, numeration, city, zipCode, state, country) => {
  const zipCodeStr = zipCode ? `Zip Code: ${zipCode} - ` : "";
  return (
    strValid(address, ",") +
    strValid(numeration, ",") +
    strValid(city, ",") +
    zipCodeStr +
    strValid(state, ",") +
    strValid(country)
  );
};
