import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const boardFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="80"
      height="96"
      viewBox="0 0 80 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 13C1.25 11.4812 2.48122 10.25 4 10.25H76C77.5188 10.25 78.75 11.4812 78.75 13V105C78.75 106.519 77.5188 107.75 76 107.75H4C2.48122 107.75 1.25 106.519 1.25 105V13Z"
        fill={frameColor}
        stroke="#404040"
        strokeWidth="2.5"
      />

      <path
        d="M21 10.25C21 7.62665 23.1266 5.5 25.75 5.5H54.25C56.8734 5.5 59 7.62665 59 10.25C59 12.8734 56.8734 15 54.25 15H25.75C23.1266 15 21 12.8734 21 10.25Z"
        fill="#404040"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 10.25C42.6232 10.25 44.75 8.12335 44.75 5.5C44.75 2.87665 42.6232 0.75 40 0.75C37.3768 0.75 35.25 2.87665 35.25 5.5C35.25 8.12335 37.3768 10.25 40 10.25ZM40 7.875C41.3116 7.875 42.375 6.81169 42.375 5.5C42.375 4.18831 41.3116 3.125 40 3.125C38.6884 3.125 37.625 4.18831 37.625 5.5C37.625 6.81169 38.6884 7.875 40 7.875Z"
        fill="#404040"
      />
      <foreignObject
        width="79%"
        height="64px"
        x="8"
        y="22">
        <div
          id="#qrCode"
          style={{
            border: `4px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            boxSizing: "border-box",
            WebkitBoxSizing: "border-box",
            padding: "1px 1px  1px 0",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="90">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="10"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
