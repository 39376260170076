import { ITimeNumbers, ITimeType } from "@/components/AnalogicalTimer/types";
import { DEFAULT_TIME } from "@/components/BusinessHours/types";
import { TimeFormat } from "@/components/TimePicker/types";
import { ITimeTimerInput, ITimeTimerInputType } from "@/components/TimerInput/types";

export const RADIUS_CONSTANT = 0.85;
export const HOUR24_RADIUS_CONSTANT = 0.6;
export const NUMBER_SIZE_PERCENT = 0.08;

export const degreesToRadians = (degrees: number) => {
  const pi = Math.PI;
  return degrees * (pi / 180);
};

export const getLimit = (type: ITimeType, limit: number) => (type === "hour24" ? limit / 2 : limit);

export const getTimeParams = (timerType: ITimeType): ITimeNumbers => {
  let params = [1, 1, 12];

  if (timerType === "hour24") {
    params = [1, 1, 24];
  } else if (timerType === "minutes") {
    params = [1, 1, 60];
  }

  return {
    start: params[0],
    step: params[1],
    limit: params[2],
  };
};

export const getMouseAngle = (
  centerX: number,
  centerY: number,
  mouseX: number,
  mouseY: number
): number => {
  // const mousy = 65.39;
  const angleRadians: number = Math.atan2(mouseY - centerY, mouseX - centerX) + Math.PI / 2;

  let angleDegrees: number = (angleRadians * 180) / Math.PI;

  if (angleDegrees < 0) {
    angleDegrees += 360;
  }

  return angleDegrees;
};

export const getRadius = (type: ITimeType, number: number, center: number) => {
  let radiusValue = center * RADIUS_CONSTANT;

  if (type === "hour24" && number > 12) {
    radiusValue = center * HOUR24_RADIUS_CONSTANT;
  }

  return radiusValue;
};

export const formatStringTimeToNumber = (time: string) => {
  if (!time) return;

  return Number(time);
};

export const formatNumberTimeToString = (time: number) => {
  if (!time) return;

  let stringifiedNumber = time.toString();
  if (stringifiedNumber?.length === 1) {
    stringifiedNumber = "0" + stringifiedNumber;
  }

  return stringifiedNumber;
};

export const formattedTime = (time: ITimeTimerInput, type: ITimeTimerInputType): string => {
  const { hour, minutes } = time;

  return `${hour === undefined ? "00" : hour}:${minutes === undefined ? "00" : minutes}${
    type === "24" ? "" : type === "AM" ? " AM" : " PM"
  }`;
};

export const inputIndexChanged = (previous: string, current: string) => {
  const differences = [];

  for (let i = 0; i < current.length; i++) {
    if (previous[i] !== current[i]) {
      differences.push(i);
    }
  }

  return differences[0];
};

export const formatTimeToView = (
  time: ITimeTimerInput,
  persistedTime: ITimeTimerInput
): ITimeTimerInput => {
  if (!time) return;

  let newTime = time;

  if (newTime?.hour?.length < 2) {
    newTime = { ...newTime, hour: persistedTime.hour };
  }
  if (newTime?.minutes?.length < 2) {
    newTime = { ...newTime, minutes: persistedTime.minutes };
  }

  return newTime;
};

export const formatToGenericTime = (time: ITimeTimerInput): string => {
  const { type, hour, minutes } = time;

  const hourNumber = Number(hour);

  let formattedHour: string;

  if (type === "AM") {
    if (hourNumber === 12) {
      // 12:00 AM to 00:00 in 24 hours format
      formattedHour = "00";
    } else {
      // Horas AM permanecen igual
      formattedHour = formatTimeLength(hourNumber);
    }
  } else if (type === "PM") {
    if (hourNumber === 12) {
      // 12:00 PM to 12:00 in 24 hours format
      formattedHour = "12";
    } else {
      // 12 hours format + 12
      formattedHour = (hourNumber + 12).toString();
    }
  } else {
    formattedHour = formatTimeLength(hourNumber);
  }

  const formattedTime = `${formattedHour}:${minutes}`;

  return formattedTime;
};

export const formatToTimerTime = (time: string, format: TimeFormat): ITimeTimerInput => {
  const [splittedHour, splittedMinutes] = time.split(":");

  let hour = splittedHour;
  const minutes = splittedMinutes;
  let type: ITimeTimerInputType;

  const hourNumber = Number(hour);

  if (format === "24") {
    type = "24";
  } else {
    if (hourNumber === 0) {
      // 00:00 to 12:00 AM in 12 hours format
      hour = "12";
      type = "AM";
    } else if (hourNumber === 12) {
      // 12:00 to 12:00 PM in 12 hours format
      hour = "12";
      type = "PM";
    } else if (hourNumber > 12) {
      hour = formatTimeLength(hourNumber - 12);
      type = "PM";
    } else {
      hour = formatTimeLength(hourNumber);
      type = "AM";
    }
  }

  const formattedTime = { hour, minutes, type };

  return formattedTime;
};

export const formatTimeLength = (time: number) => {
  let formattedTime = time.toString();

  if (formattedTime.length === 1) {
    formattedTime = `0${formattedTime}`;
  }

  return formattedTime;
};

export const formatToBusinessHoursInput = (time: string, format: TimeFormat) => {
  if (time === undefined || time === "") return;

  let formattedTime = time;

  if (format === "AM/PM") {
    const [hourString, minutesString] = time.split(":");
    const hourNumber = Number(hourString);
    const minutesNumber = Number(minutesString);

    let period = "am";
    let hour12Format = hourNumber;

    if (hourNumber === 0) {
      // 00:00 to 12:00 AM
      hour12Format = 12;
      period = "am";
    } else if (hourNumber === 12) {
      // 12:00 to 12:00 PM
      hour12Format = 12;
      period = "pm";
    } else if (hourNumber > 12) {
      hour12Format = hourNumber - 12;
      period = "pm";
    } else {
      period = "am";
    }

    formattedTime = `${formatTimeLength(hour12Format)}:${formatTimeLength(minutesNumber)}${period}`;
  }

  return formattedTime;
};

export const formatToBusinessHoursPreview = (time: string, format: TimeFormat) => {
  if (time === undefined) return;

  if (time === "") {
    const hourNumber = Number(DEFAULT_TIME.split(":")[0]);

    return `${DEFAULT_TIME}${
      format === "AM/PM" ? (hourNumber === 0 || hourNumber > 12 ? "pm" : "am") : ""
    }`;
  }

  let formattedTime = time;

  if (format === "AM/PM") {
    const hourNumber = Number(time.split(":")[0]);
    const minutesNumber = Number(time.split(":")[1]);

    if (hourNumber === 0 || hourNumber > 12) {
      formattedTime = `${
        hourNumber === 0 ? 12 : formatTimeLength(hourNumber - 12)
      }:${formatTimeLength(minutesNumber)}pm`;
    } else {
      formattedTime = `${formatTimeLength(hourNumber)}:${formatTimeLength(minutesNumber)}am`;
    }
  }

  return formattedTime;
};

export const buildTime = (
  originalTime: ITimeTimerInput,
  key: keyof ITimeTimerInput,
  value: string
): ITimeTimerInput => {
  return {
    ...originalTime,
    [key]: value,
  };
};
