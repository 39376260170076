import { CtaStyle } from "../Button/types";
import { TimeFormat } from "../TimePicker/types";

export const DEFAULT_TIME = "08:00";

export interface BusinessHourTime {
  start: string;
  end: string;
}

export interface BusinessHoursDay {
  enabled: boolean;
  times: BusinessHourTime[];
}

export type BusinessHoursDays = {
  monday: BusinessHoursDay;
  tuesday: BusinessHoursDay;
  wednesday: BusinessHoursDay;
  thursday: BusinessHoursDay;
  friday: BusinessHoursDay;
  saturday: BusinessHoursDay;
  sunday: BusinessHoursDay;
};

export interface BusinessHours {
  format: TimeFormat;
  days: BusinessHoursDays;
}

export interface BusinessHourProps {
  defaultValue: BusinessHours;
  onClick?: (hours: BusinessHours) => void;
  ctaStyle?: CtaStyle
}

export interface BusinessHourInputProps {
  defaultValue?: string;
  placeholder?: string;
  isEnabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
