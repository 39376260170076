import React from "react";

import { QRFRameToPrintProps } from "../types";
import { uriText } from "./uri";

import "../styles.scss";

export const NoneFrameToDownload = (props: QRFRameToPrintProps): React.SVGProps<SVGSVGElement> => {
  const { qrSvg } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={623}
      height={623}
      fill="none">
      <g>
        <title>Layer 1</title>
        <g
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(6.25794 0 0 6.25794 0 190)"
          stroke="null"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="260"
          y="0"
          x="0">
          <style>
            {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
          </style>
        </svg>
      </g>
    </svg>
  );
};
